<template>
  <div class="row no-wrap text-8">
    <!-- right analyze organization, NOT VOC_USER_SIMPLE_UI -->
    <template v-if="organizationID || user?.FocusOrganizationID">
      <q-btn
        dense
        unelevated
        no-caps
        aria-label="Voccent"
        class="text-v round-1 q-py-none q-mx-none q-my-auto"
        style="
          cursor:
            url(&quot;Cc.svg&quot;) 4 12,
            auto !important;
          height: 3rem;
          padding-top: -0.5rem !important;
          padding-bottom: 0.5rem !important;
          padding-right: 0.1rem;
          padding-left: 0.1rem;
          position: relative;
        "
        @click="SignInOrPush()"
        @mouseleave="hit = false"
      >
        <!-- Note: ref-de016097: added thematic image -->
        <!-- <q-img
             width="35px"
             src="doodles/christmas/voccent-the-snowman.png"
             no-spinner
             class="q-mr-sm"
             /> -->
        <img
          :style="organizationLogoStyle"
          @load="onOrganizationImageLoad"
          @error="onOrganizationImageLoadError"
          ref="ref_main_organization_image"
          :src="organizationLogoSrc"
          v-if="organizationLogoSrc"
        />
      </q-btn>
    </template>
    <!-- :style="{ width: `calc(${(`+q.screen.gt.sm +` ? 95 : 65) + 35 + 10}px)` }" -->
    <q-btn
      v-else
      dense
      unelevated
      no-caps
      aria-label="Voccent"
      class="text-v round-1 q-py-none q-mx-none q-my-auto"
      style="
        cursor:
          url(&quot;Cc.svg&quot;) 4 12,
          auto !important;
        height: 3rem;
        padding-top: -0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-right: 0.1rem;
        padding-left: 0.1rem;
        position: relative;
      "
      :style="{ width: `calc(${35 + 95 + 10}px)` }"
      @click="SignInOrPush()"
      @mouseleave="hit = false"
    >
      <!-- Note: ref-de016097: added thematic image -->
      <!-- <q-img
           :height="$q.screen.gt.sm ? '2rem' : '1.4rem'"
           :width="$q.screen.gt.sm ? '95px' : '65px'"
           :class="$q.screen.gt.sm ? 'q-mb-xs' : ''"
           class="
           src="doodles/halloween/voccent-trick-to-speak-pumpkin.png"
           no-spinner
           /> -->
      <!-- <q-img
           width="35px"
           src="doodles/christmas/voccent-the-snowman.png"
           no-spinner
           /> -->
      <svg
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 1250 400"
        height="3rem"
        width="95px"
        class="bg-transparent"
        style="stroke: currentColor"
      >
        <symbol id="voccent3000">
          <path d="M355.87 98.92C352.91 122.85 383.74 130.24 375.1 155" />
          <path
            d="M305.85 122.5C188.02 148.38 138.24 231.27 156.51 371.17L120.01 371.25L41.89 140"
          />
          <path
            d="M380.88 243.3C352.88 214.71 303.51 215.37 275.23 243.5C246.94 271.63 246.28 320.31 274.51 348.66C302.75 377 351.43 377.99 380.35 349.28C409.26 320.56 404.9 268.35 380.88 243.3Z"
          />
          <path
            d="M514.87 387.09C465.07 242.99 540.53 87.41 685.36 37.54"
            @mousemove="hit = true"
            :class="hit ? 'text-v' : ''"
          />
          <path
            d="M608.96 353.46C577.28 261.76 625.29 162.76 717.45 131.02"
            :class="hit ? 'text-v' : ''"
          />
          <path
            d="M727.84 341.4C795.32 276.55 828.56 243.78 827.55 243.09C799.55 214.49 750.18 215.16 721.89 243.29C693.61 271.42 692.94 320.1 721.18 348.44C749.42 376.79 798.1 377.78 827.01 349.06"
          />
          <path
            d="M1007.25 385.75C1028.15 332.83 1024.07 234.95 1008.44 215C998.02 201.7 968.38 219.86 919.5 269.5C926.14 307.99 919.23 346.07 898.8 383.75"
          />
          <path
            d="M1225.61 364.33C1020.07 421.65 1171.98 166.24 1141.11 82.7"
          />
          <path
            d="M1076.82 174.78C1106.82 163.25 1155.66 214.22 1197.82 206.03"
          />
        </symbol>
        <use xlink:href="#voccent3000" stroke-width="52" fill="none" />
      </svg>
      <div
        v-if="hit"
        class="text-v"
        style="position: absolute; top: 0; right: -1rem"
      >
        Yay!
      </div>
    </q-btn>
    <div
      v-if="
        $route.name &&
        !(
          $route.name === 'route-main-page' ||
          $route.name === '-raw-route-channel' ||
          $route.name === '-raw-route-empty-channel-name' ||
          $route.name === '-raw-route-challenge' ||
          $route.name === '-raw-route-playlist' ||
          $route.name === 'route-story-studio' ||
          $route.name === 'route-story-pass' ||
          $route.name === 'route-story-pass-history' ||
          $route.name === 'route-plan-pass-user' ||
          $route.name === 'route-plan-pass-author' ||
          $route.name === 'route-library' ||
          $route.name === 'route-library-system' ||
          $route.name === 'route-mixer' ||
          $route.name === 'route-anomalies' ||
          $route.name === 'route-classroom' ||
          $route.name === 'route-campus' ||
          $route.name === 'route-challenge-studio' ||
          $route.name === 'route-organization'
        )
      "
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap"
    >
      <div>{{ $t($route.name) }}</div>
    </div>
    <!-- Push into currentchannel-->
    <div
      v-if="
        $route.name &&
        ($route.name === '-raw-route-channel' ||
          $route.name === '-raw-route-empty-channel-name' ||
          $route.name === '-raw-route-challenge' ||
          $route.name === '-raw-route-playlist' ||
          $route.name === 'route-story-studio' ||
          $route.name === 'route-story-pass' ||
          $route.name === 'route-story-pass-history' ||
          $route.name === 'route-challenge-studio')
      "
      class="row no-wrap q-my-auto q-gutter-x-xs"
      style="white-space: nowrap"
      @click="$router.push('/c/' + currentchannel.Name)"
    >
      <b
        v-if="
          isInPlaylistOrLesson ||
          $route.name === 'route-story-studio' ||
          $route.name === 'route-story-pass' ||
          $route.name === 'route-story-pass-history' ||
          $route.name === 'route-challenge-studio'
        "
      ></b>
      <div
        v-if="
          isInPlaylistOrLesson ||
          $route.name === 'route-story-studio' ||
          $route.name === 'route-story-pass' ||
          $route.name === 'route-story-pass-history' ||
          $route.name === 'route-challenge-studio'
        "
        style="cursor: pointer"
      >
        <q-btn
          dense
          no-caps
          unelevated
          class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
          style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
        >
          {{ currentchannel?.Name }}
        </q-btn>
      </div>

      <b></b>

      <div
        v-if="
          $route.name === '-raw-route-channel' ||
          $route.name === '-raw-route-empty-channel-name'
        "
        style="cursor: pointer"
      >
        <q-btn
          v-if="!$store.getters.currentchannel.Edit"
          dense
          unelevated
          no-caps
          class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
          style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
        >
          <template v-if="$route.name === '-raw-route-empty-channel-name'">
            {{ $store.getters.currentchannel.Name }}
          </template>
          <template v-else>{{ routEnd }}</template>
        </q-btn>
        <!-- Edit channel name -->
        <q-input
          v-else
          :input-style="
            $q.screen.gt.sm
              ? 'color: var(--q-v); margin-top: -.5rem !important; font-weight: 1000; font-size: 1.5rem;'
              : 'color: var(--q-v); margin-top: -.5rem !important; font-weight: 1000; font-size: 1rem;'
          "
          borderless
          class="bg-0 q-px-md"
          input-class="ubuntu"
          placeholder="Channel Name"
          type="text"
          style="
            border-radius: 0.75rem;
            border: 2px solid var(--q-2);
            height: 3rem;
          "
          :model-value="$store.getters.currentchannel?.NewName"
          @update:model-value="
            (newVal) => $store.commit('setCurrentChannelNewName', newVal)
          "
          lazy-rules
          :rules="[
            (val) =>
              h.reduceString(val).length >= 2 || $t('-raw-use-min-2-chars'),
            (val) =>
              h.reduceString(val).length < 42 || $t('-raw-use-max-42-chars'),
          ]"
        />
      </div>

      <!-- NOTE: Channel Name -->
      <div v-if="$route.name === '-raw-route-playlist'" style="cursor: pointer">
        <q-btn
          dense
          unelevated
          no-caps
          class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
          style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
        >
          {{ playlistName }}
        </q-btn>
      </div>
      <!-- NOTE: Playlist Name -->
      <div
        v-if="
          ($route.name === '-raw-route-challenge' ||
            $route.name === '-raw-route-playlist-item') &&
          challenge
        "
        style="cursor: pointer"
      >
        <q-btn
          dense
          unelevated
          no-caps
          class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
          style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
        >
          {{ challenge.Name }}
        </q-btn>
      </div>
    </div>
    <div
      v-if="
        $route.name === 'route-story-studio' ||
        $route.name === 'route-story-pass' ||
        $route.name === 'route-story-pass-history'
      "
      class="row no-wrap q-my-auto q-px-sm"
      style="white-space: nowrap; cursor: pointer"
    >
      <div
        class="bg-1 row items-center no-wrap q-my-auto q-px-md round-1 q-py-none"
        style="
          white-space: nowrap;
          font-size: 1.5rem;
          height: 3rem;
          font-weight: bold;
        "
        @click="$router.push('/c/' + currentchannel.Name)"
      >
        {{ currentStory.Name }}
      </div>
    </div>
    <div
      v-if="$route.name === 'route-challenge-studio'"
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap; cursor: pointer"
    >
      <div
        class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
        style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
        @click="$router.push('/c/' + currentchannel.Name)"
      >
        {{ currentChallenge?.Name }}
      </div>
    </div>
    <div
      v-if="$route.name === 'route-organization'"
      class="row no-wrap q-my-auto q-px-md"
      q-gutter-x-xs
      style="white-space: nowrap; cursor: pointer"
    >
      <div
        class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
        style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
        @click="$router.push('/organization/' + currentOrganization.ID)"
      >
        {{ currentOrganization?.Name }}
      </div>
    </div>
    <div
      v-if="$route.name === 'route-library-system'"
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap"
    >
      <div
        class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
        style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
      >
        Public library
      </div>
    </div>
    <div
      v-if="$route.name === 'route-library'"
      class="row no-wrap q-my-auto q-px-mdq-gutter-x-xs"
      style="white-space: nowrap"
    >
      <div
        class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
        style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
      >
        <span class="first-letter-upper">{{ currentLibrary?.Type }}</span>
        &nbsp;library
      </div>
    </div>
    <div
      v-if="$route.name === 'route-mixer'"
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap"
    >
      Mixer
    </div>
    <div
      v-if="$route.name === 'route-anomalies'"
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap"
    >
      Anomalies
    </div>

    <div
      v-if="
        $route.name &&
        ($route.name === 'route-classroom' ||
          $route.name === 'route-campus' ||
          $route.name === 'route-plan-pass-user' ||
          $route.name === 'route-plan-pass-author')
      "
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap"
    >
      <div
        class="row"
        @click="
          switch (this.$route.name) {
            case 'route-classroom':
              $router.push('/authoring/classrooms');
              break;
            case 'route-campus':
              $router.push('/authoring/campuses');
              break;
            case 'route-plan-pass-user':
              if (h.getItem('VOC_USER_SIMPLE_UI') === true) {
                $router.push('/?clid=' + (this.currentPlan?.ClassroomID || ''));
              } else {
                $router.push(
                  '/my-classrooms?clid=' +
                    (this.currentPlan?.ClassroomID || ''),
                );
              }
              break;
            case 'route-plan-pass-author':
              $router.push(
                '/campus/' +
                  this.currentPlan.CampusID +
                  '?pid=' +
                  this.currentPlan.ID,
              );
              break;
            default:
              break;
          }
        "
      >
        <div style="cursor: pointer">
          <q-btn
            dense
            unelevated
            no-caps
            class="bg-1 row no-wrap q-my-auto q-px-md round-1 q-py-none"
            style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
          >
            <template
              v-if="
                $route.name === 'route-classroom' ||
                $route.name === 'route-campus'
              "
            >
              {{ $t($route.name) }}
            </template>
            <template
              v-else-if="
                $route.name === 'route-plan-pass-user' ||
                $route.name === 'route-plan-pass-author'
              "
            >
              {{ $t("-raw-campus-plan") }}
            </template>
          </q-btn>
        </div>

        <div style="cursor: pointer">
          <q-btn
            dense
            unelevated
            no-caps
            class="bg-0 row no-wrap q-my-auto q-px-lg round-1 q-py-none"
            style="white-space: nowrap; font-size: 1.5rem; font-weight: bold"
          >
            <template v-if="$route.name === 'route-classroom'">
              {{ currentClassroom.Name }}
            </template>
            <template v-else-if="$route.name === 'route-campus'">
              {{ currentCampus.Name }}
            </template>
            <template
              v-else-if="
                $route.name === 'route-plan-pass-user' ||
                $route.name === 'route-plan-pass-author'
              "
            >
              {{ currentPlan.Name }}
            </template>
          </q-btn>
        </div>
      </div>

      <div
        v-if="
          $route.name &&
          ($route.name === 'route-plan-pass-user' ||
            $route.name === 'route-plan-pass-author') &&
          currentPlanElement.Name
        "
        class="row no-wrap q-my-auto q-px-md"
        style="white-space: nowrap"
      >
        {{ currentPlanElement.Name }}
        <q-btn
          round
          dense
          flat
          icon="info"
          class="text-d q-ml-xs"
          size="sm"
          v-if="currentPlanElement.Description"
        >
          <q-tooltip
            style="font-size: 1rem"
            class="round-both ubuntu bg-0 text-d items-center q-px-md"
            transition-show="jump-right"
            transition-hide="jump-left"
            max-width="20rem"
          >
            <div class="column items-center q-ma-sm">
              {{ currentPlanElement.Description }}
            </div>
          </q-tooltip>
        </q-btn>
      </div>
    </div>

    <div
      v-if="
        $route.name &&
        ($route.name === '-raw-route-user-classrooms' ||
          $route.name === 'route-main-page') &&
        currentClassroom.Name
      "
      class="row no-wrap q-my-auto q-px-md"
      style="white-space: nowrap"
    >
      {{ currentClassroom.Name }}
    </div>

    <!-- <div v-if="$route.path === '/' && user && $q.screen.gt.md">
         <div class="row text-center justify-center">
         <activitygraph />
         </div>
         </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import h from "src/h/helpers.js";

export default {
  data: () => ({
    hit: false,
    organizationLogoStyle: {
      height: "", // $q.screen.gt.sm ? "3rem" : "1.1rem",
      maxWidth: "none", // hide before load
    },
    organizationLogoSrc: null,
  }),
  mounted() {
    console.log(this.$route);
    this.CheckOrganizationLogoSrc();
  },
  created() {
    this.organizationLogoStyle.height = this.$q.screen.gt.sm
      ? "3rem"
      : "1.1rem";
  },
  computed: {
    h() {
      return h;
    },
    routEnd() {
      return /[^/]*$/.exec(decodeURI(this.$route.path))[0];
    },
    subPath() {
      const sec =
        this.$route.path.indexOf("/", 1) > 0
          ? this.$route.path.indexOf("/", 1)
          : this.$route.path.length;
      return sec;
    },
    playlistName() {
      return this.$store.getters.playlistName;
    },
    challenge() {
      return this.$store.getters.createdChallenge;
    },
    user() {
      return this.$store.getters.user;
    },
    currentStory() {
      return this.$store.getters.currentStory;
    },
    currentChallenge() {
      return this.$store.getters.currentChallenge;
    },
    currentOrganization() {
      return this.$store.getters.currentOrganization;
    },
    currentPlan() {
      return this.$store.getters.currentCampusPlan;
    },
    currentPlanElement() {
      return this.$store.getters.currentCampusPlanElement;
    },
    currentCampus() {
      return this.$store.getters.currentCampus;
    },
    currentClassroom() {
      return this.$store.getters.currentClassroom;
    },
    isInPlaylistOrLesson() {
      return (
        !!this.$store.getters.playlistID ||
        !!this.$store.state.lessonStudio.lessonName ||
        this.$route.name === "-raw-route-challenge"
      );
    },
    currentLibrary() {
      return this.$store.getters.currentLibrary;
    },
    organizationID() {
      return this.$store.getters.organizationID;
    },
    ...mapGetters(["createdChallenge", "currentchannel"]),
  },
  watch: {
    "$store.getters.organizationChanged": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.CheckOrganizationLogoSrc();
        }
      },
    },
    "user.FocusOrganizationID": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.CheckOrganizationLogoSrc();
        }
      },
    },
    organizationID: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.CheckOrganizationLogoSrc();
        }
      },
    },
  },
  methods: {
    CheckOrganizationLogoSrc() {
      if (this.organizationID || this.user?.FocusOrganizationID) {
        this.organizationLogoSrc =
          "/api/v1/asset/object/organization_picture/" +
          (this.organizationID
            ? this.organizationID
            : this.user?.FocusOrganizationID) +
          "?t=" +
          h.GetNanoSecTime();
      }
    },
    SignInOrPush() {
      // if (!this.$store.getters.user) this.GoogleSignIn();
      // else this.$router.push("/");
      this.$router.push("/");
    },
    GoogleSignIn() {
      this.$store.dispatch(
        "userProvider",
        new firebase.auth.GoogleAuthProvider(),
      );
    },
    onOrganizationImageLoad() {
      const img = this.$refs.ref_main_organization_image;

      if (img) {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        this.organizationLogoStyle.width = `${50 * aspectRatio}px`;
      }
    },
    onOrganizationImageLoadError() {
      this.organizationLogoSrc = "Cc.svg";
    },
  },
};
</script>

<style scoped>
.first-letter-upper {
  text-transform: capitalize;
}
</style>
