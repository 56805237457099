<template>
  <div
    class="column full-height justify-end"
    style="position: relative; overflow: hidden"
    id="classroom-content"
  >
    <div class="col column bg-1" style="">
      <q-card
        flat
        class="full-width bg-transparent"
        style="border-top-left-radius: 1rem; border-top-right-radius: 1rem"
      >
        <div class="column full-width justify-center items-center">
          <q-tabs
            v-model="tabclassroom"
            indicator-color="v"
            align="justify"
            narrow-indicator
            class="text-9 bg-2 ubuntu overflow-hidden"
            active-color="v"
            active-bg-color="1"
            style="width: 0; min-width: 100%"
            inline-label
            mobile-arrows
          >
            <q-tab
              name="plans"
              class="q-py-md"
              :style="{
                display: !accessUpdate ? 'none' : '',
              }"
            >
              <q-icon size="sm" name="schedule" />
              <div class="q-px-xs">{{ $t("-raw-classroom-tab-plans") }}</div>
            </q-tab>
            <q-tab name="people" class="q-py-md" v-show="accessUpdate">
              <q-icon size="sm" name="face" />
              <div class="q-px-xs">{{ $t("-raw-classroom-tab-people") }}</div>
            </q-tab>
            <q-tab name="campuses" class="q-py-md" v-show="accessUpdate">
              <q-icon size="sm" name="school" />
              <div class="q-px-xs">
                {{ $t("-raw-classroom-tab-campuses-mentors") }}
              </div>
            </q-tab>
            <q-tab name="reports" class="q-py-md" v-show="accessUpdate">
              <q-icon size="sm" name="description" />
              <div class="q-px-xs">{{ $t("-raw-classroom-tab-reports") }}</div>
            </q-tab>
          </q-tabs>

          <q-tab-panels
            v-model="tabclassroom"
            style="background: transparent"
            animated
            class="col full-width"
            keep-alive
            keep-alive-include="reports"
          >
            <q-tab-panel name="plans" class="q-mt-xs">
              <div class="fit" v-if="accessUpdate">
                <div class="row justify-center">
                  <!-- plans -->
                  <div
                    class="col-xs-10 col-sm-10 col-md-9 col-lg-9 col-xl-9 q-pa-xs q-mb-sm text-8 q-pa-lg"
                  >
                    <q-btn-toggle
                      v-model="plantoggleshow"
                      no-caps
                      spread
                      rounded
                      class="plans-toggle"
                      toggle-color="v"
                      :options="[
                        { value: 'current', slot: 'current' },
                        { value: 'completed', slot: 'completed' },
                      ]"
                    >
                      <template #current>
                        <div
                          :class="
                            plantoggleshow == 'current' ? 'text-bold' : 'text-v'
                          "
                        >
                          {{ $t("-raw-campus-plans-current") }}
                        </div>
                      </template>

                      <template #completed>
                        <div
                          :class="
                            plantoggleshow == 'completed'
                              ? 'text-bold'
                              : 'text-e'
                          "
                        >
                          {{ $t("-raw-campus-plans-completed") }}
                        </div>
                      </template>
                    </q-btn-toggle>
                  </div>
                </div>
              </div>

              <div
                class="row justify-center"
                v-show="plantoggleshow == 'current'"
              >
                <q-slide-transition>
                  <div
                    class="column row items-center q-pa-md"
                    v-if="!loadedplanscurrent"
                  >
                    <q-spinner-ball color="primary" size="lg" />
                  </div>
                  <div
                    class="row full-width items-center justify-center q-pb-sm q-pt-sm"
                    v-else
                  >
                    <div
                      class="row full-width items-center justify-center"
                      v-if="currentPlans.length === 0"
                    >
                      <div
                        class="justify-center text-center text-e col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                      >
                        {{ $t("-raw-404-nothing-found-v2") }}
                      </div>
                    </div>

                    <q-carousel
                      v-else
                      class="bg-transparent q-px-xl col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"
                      v-model="curPlanSlide"
                      ref="carousel"
                    >
                      <template #control>
                        <!-- Previous Button -->
                        <q-carousel-control
                          v-if="curPlanSlide > 0"
                          position="left"
                          :offset="[0,78]"
                        >
                          <q-btn
                            push
                            round
                            color="v"
                            size="md"
                            @click="$refs.carousel.previous()"
                          >
                            <q-icon
                              name="navigate_before"
                              color="black"
                              size="lg"
                            />
                            <q-tooltip
                              anchor="top right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              self="bottom right"
                              transition-hide="jump-left"
                              transition-show="jump-right"
                            >
                              {{ $t("-raw-previous") }}
                            </q-tooltip>
                          </q-btn>
                        </q-carousel-control>

                        <!-- Next Button -->
                        <q-carousel-control
                          v-if="curPlanSlide < validCurPlans.length - 1"
                          position="right"
                          :offset="[0,78]"
                        >
                          <q-btn
                            push
                            round
                            color="v"
                            size="md"
                            @click="$refs.carousel.next()"
                          >
                            <q-icon
                              name="navigate_next"
                              color="black"
                              size="lg"
                            />

                            <q-tooltip
                              anchor="top right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              self="bottom right"
                              transition-hide="jump-left"
                              transition-show="jump-right"
                            >
                              {{ $t("-raw-next") }}
                            </q-tooltip>
                          </q-btn>
                        </q-carousel-control>
                      </template>

                      <q-carousel-slide
                        v-for="(pl, i) in validCurPlans"
                        :key="'curpln_' + pl.ID"
                        :name="i"
                      >
                        <!-- Plan Card -->
                        <div
                          class="column bg-2 q-pa-sm"
                          style="
                            border-radius: 0.75rem;
                            border: 2px solid var(--q-4);
                          "
                        >
                          <div class="row" style="height: 9rem">
                            <!-- Plan Image -->
                            <div class="col-auto q-mr-md">
                              <q-avatar
                                size="9rem"
                                style="border-radius: 0.75rem"
                                class="bg-1"
                              >
                                <pickerimage
                                  class="full-width full-height"
                                  :ref="'ref_plan_image_' + pl.PlanID"
                                  :data="{
                                    uid: 'plan_image_' + pl.PlanID,
                                    objectid: pl.PlanID,
                                    objecttype: 'campus_plan_picture',
                                  }"
                                  :view="{
                                    onlyshow: true,
                                    alwaysget: false,
                                  }"
                                />
                              </q-avatar>
                            </div>

                            <!-- Plan Metadata -->
                            <div class="col">
                              <div
                                class="row items-center"
                                style="height: 2.25rem; overflow: hidden;"
                              >
                                <!-- Plan Name -->
                                <div
                                  class="col q-mr-sm"
                                  style="
                                    height: 2.25rem;
                                    font-size: 1.75rem;
                                    font-weight: bold;
                                    overflow: hidden;
                                  "
                                >
                                  {{ pl.Name }}

                                  <q-tooltip
                                    anchor="top right"
                                    class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                    self="bottom right"
                                    transition-hide="jump-left"
                                    transition-show="jump-right"
                                  >
                                    {{ pl.Name }}
                                  </q-tooltip>
                                </div>

                                <!-- Plan Info -->
                                <div v-if="pl.Description" class="col-auto">
                                  <q-icon
                                    size="sm"
                                    round
                                    name="info_outline"
                                  >
                                    <q-tooltip
                                      anchor="top right"
                                      self="bottom right"
                                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                      transition-show="jump-right"
                                      transition-hide="jump-left"
                                    >
                                      <div class="q-mr-md">
                                        {{ $t("-raw-general-campus") }}:
                                        {{ pl.CampusName }}
                                      </div>

                                      <div class="q-mr-md">
                                        {{ $t("-raw-campus-plan-element-count") }}:
                                        {{ pl.ElementCount }}
                                      </div>

                                      <div>
                                        {{ $t("-raw-campus-plan-start-at") }}:
                                        {{ handleDate(pl.StartAt) }}
                                      </div>

                                      <div class="q-ml-md" v-if="pl.EndAt !== null">
                                        {{ $t("-raw-campus-plan-end-at") }}:
                                        {{ handleDate(pl.EndAt) }}
                                      </div>
                                    </q-tooltip>
                                  </q-icon>
                                </div>
                              </div>

                              <!-- Descritpion -->
                              <div
                                v-if="pl.Description"
                                class="row"
                                style="
                                  height: 2.5rem;
                                  font-size: 0.85rem;
                                  font-weight: normal;
                                  margin-bottom: 0.5rem;
                                "
                              >
                                <q-scroll-area
                                  :style="{
                                    height: '2.5rem',
                                    width: '100%',
                                  }"
                                >
                                  {{ pl.Description }}
                                </q-scroll-area>
                              </div>

                              <div
                                v-else
                                class="row"
                                style="
                                  height: 1.1rem;
                                  font-size: 0.85rem;
                                  font-weight: normal;
                                  overflow: hidden;
                                  white-space: nowrap;
                                  margin-bottom: 1.9rem;
                                "
                              >
                                <div class="col-auto q-mr-md">
                                  {{ $t("-raw-general-campus") }}:
                                  {{ pl.CampusName }}
                                </div>

                                <div class="col-auto q-mr-md">
                                  {{ $t("-raw-campus-plan-element-count") }}:
                                  {{ pl.ElementCount }}
                                </div>

                                <div class="col-auto">
                                  {{ $t("-raw-campus-plan-start-at") }}:
                                  {{ handleDate(pl.StartAt) }}
                                </div>

                                <div class="col-auto q-ml-md" v-if="pl.EndAt !== null">
                                  {{ $t("-raw-campus-plan-end-at") }}:
                                  {{ handleDate(pl.EndAt) }}
                                </div>
                              </div>

                              <!-- Buttons -->
                              <div
                                class="row"
                                style="height: 3.75rem; overflow: hidden;"
                              >
                                <div class="col-auto q-mr-md">
                                  <q-btn
                                    round
                                    push
                                    :color="pl.UserPassing ? 'w': 'p'"
                                    size="1.25rem"
                                    @click.stop="
                                      $router.push({
                                        path: `/plan-pass/${pl.PlanID}/${pl.ID}/new`,
                                      })
                                    "
                                  >
                                    <q-icon
                                      :name="
                                        pl.UserPassing
                                          ? 'refresh'
                                          : 'play_arrow'
                                      "
                                      color="black"
                                      size="xl"
                                    />

                                    <q-tooltip
                                      anchor="top right"
                                      self="bottom right"
                                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                      transition-show="jump-right"
                                      transition-hide="jump-left"
                                    >
                                      {{ pl.UserPassing ? $t('-raw-campus-plan-pass-start-again',) : $t('-raw-campus-plan-pass') }}
                                    </q-tooltip>
                                  </q-btn>
                                </div>

                                <div class="col-auto q-mr-sm">
                                  <q-btn
                                    round
                                    push
                                    v-if="pl.UserPassing"
                                    color="p"
                                    size="1.25rem"
                                    @click.stop="
                                      $router.push({
                                        path: `/plan-pass/${pl.PlanID}/${pl.ID}/continue`,
                                      })
                                    "
                                  >
                                    <q-icon
                                      name="east"
                                      color="black"
                                      size="xl"
                                    />

                                    <q-tooltip
                                      anchor="top right"
                                      self="bottom right"
                                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                      transition-show="jump-right"
                                      transition-hide="jump-left"
                                    >
                                      {{ $t('-raw-campus-plan-pass-continue') }}
                                    </q-tooltip>
                                  </q-btn>
                                </div>

                                <div class="col" style="position: relative;">
                                  <q-badge
                                    rounded
                                    color="5"
                                    style="position: absolute; bottom: 0; right: 0; user-select: none;"
                                  >
                                    {{ (i + 1) + "/" + validCurPlans.length }}
                                  </q-badge>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </q-carousel-slide>
                    </q-carousel>
                  </div>
                </q-slide-transition>
              </div>

              <div
                class="row justify-center"
                v-show="plantoggleshow == 'completed'"
              >
                <q-slide-transition>
                  <div
                    class="column row items-center q-pa-md"
                    v-if="!loadedplanscompleted"
                  >
                    <q-spinner-ball color="primary" size="lg" />
                  </div>
                  <div
                    class="row full-width items-center justify-center q-pb-sm q-pt-sm"
                    v-else
                  >
                    <div
                      class="row full-width items-center justify-center"
                      v-if="completedPlans.length === 0"
                    >
                      <div
                        class="justify-center text-center text-e col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                      >
                        {{ $t("-raw-404-nothing-found-v2") }}
                      </div>
                    </div>
                    <q-carousel
                      v-else
                      class="bg-transparent q-px-xl col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"
                      v-model="comPlanSlide"
                      ref="carousel"
                    >
                      <template #control>
                        <!-- Previous Button -->
                        <q-carousel-control
                          v-if="comPlanSlide > 0"
                          position="left"
                          :offset="[0,78]"
                        >
                          <q-btn
                            push
                            round
                            color="v"
                            size="md"
                            @click="$refs.carousel.previous()"
                          >
                            <q-icon
                              name="navigate_before"
                              color="black"
                              size="lg"
                            />
                            <q-tooltip
                              anchor="top right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              self="bottom right"
                              transition-hide="jump-left"
                              transition-show="jump-right"
                            >
                              {{ $t("-raw-previous") }}
                            </q-tooltip>
                          </q-btn>
                        </q-carousel-control>

                        <!-- Next Button -->
                        <q-carousel-control
                          v-if="comPlanSlide < validComPlans.length - 1"
                          position="right"
                          :offset="[0,78]"
                        >
                          <q-btn
                            push
                            round
                            color="v"
                            size="md"
                            @click="$refs.carousel.next()"
                          >
                            <q-icon
                              name="navigate_next"
                              color="black"
                              size="lg"
                            />

                            <q-tooltip
                              anchor="top right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              self="bottom right"
                              transition-hide="jump-left"
                              transition-show="jump-right"
                            >
                              {{ $t("-raw-next") }}
                            </q-tooltip>
                          </q-btn>
                        </q-carousel-control>
                      </template>

                      <q-carousel-slide
                        v-for="(pl, i) in validComPlans"
                        :key="'curpln_' + pl.ID"
                        :name="i"
                      >
                        <!-- Plan Card -->
                        <div
                          class="column bg-2 q-pa-sm"
                          style="
                            border-radius: 0.75rem;
                            border: 2px solid var(--q-4);
                          "
                        >
                          <div class="row" style="height: 9rem">
                            <!-- Plan Image -->
                            <div class="col-auto q-mr-md">
                              <q-avatar
                                size="9rem"
                                style="border-radius: 0.75rem"
                                class="bg-1"
                              >
                                <pickerimage
                                  class="full-width full-height"
                                  :ref="'ref_plan_image_' + pl.PlanID"
                                  :data="{
                                    uid: 'plan_image_' + pl.PlanID,
                                    objectid: pl.PlanID,
                                    objecttype: 'campus_plan_picture',
                                  }"
                                  :view="{
                                    onlyshow: true,
                                    alwaysget: false,
                                  }"
                                />
                              </q-avatar>
                            </div>

                            <!-- Plan Metadata -->
                            <div class="col">
                              <div
                                class="row items-center"
                                style="height: 2.25rem; overflow: hidden;"
                              >
                                <!-- Plan Name -->
                                <div
                                  class="col q-mr-sm"
                                  style="
                                    height: 2.25rem;
                                    font-size: 1.75rem;
                                    font-weight: bold;
                                    overflow: hidden;
                                  "
                                >
                                  {{ pl.Name }}

                                  <q-tooltip
                                    anchor="top right"
                                    class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                    self="bottom right"
                                    transition-hide="jump-left"
                                    transition-show="jump-right"
                                  >
                                    {{ pl.Name }}
                                  </q-tooltip>
                                </div>

                                <!-- Plan Info -->
                                <div v-if="pl.Description" class="col-auto">
                                  <q-icon
                                    size="sm"
                                    round
                                    name="info_outline"
                                  >
                                    <q-tooltip
                                      anchor="top right"
                                      self="bottom right"
                                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                      transition-show="jump-right"
                                      transition-hide="jump-left"
                                    >
                                      <div class="q-mr-md">
                                        {{ $t("-raw-general-campus") }}:
                                        {{ pl.CampusName }}
                                      </div>

                                      <div class="q-mr-md">
                                        {{ $t("-raw-campus-plan-element-count") }}:
                                        {{ pl.ElementCount }}
                                      </div>

                                      <div>
                                        {{ $t("-raw-campus-plan-start-at") }}:
                                        {{ handleDate(pl.StartAt) }}
                                      </div>

                                      <div class="q-ml-md" v-if="pl.EndAt !== null">
                                        {{ $t("-raw-campus-plan-end-at") }}:
                                        {{ handleDate(pl.EndAt) }}
                                      </div>
                                    </q-tooltip>
                                  </q-icon>
                                </div>
                              </div>

                              <!-- Descritpion -->
                              <div
                                v-if="pl.Description"
                                class="row"
                                style="
                                  height: 2.5rem;
                                  font-size: 0.85rem;
                                  font-weight: normal;
                                  margin-bottom: 0.5rem;
                                "
                              >
                                <q-scroll-area
                                  :style="{
                                    height: '2.5rem',
                                    width: '100%',
                                  }"
                                >
                                  {{ pl.Description }}
                                </q-scroll-area>
                              </div>

                              <div
                                v-else
                                class="row"
                                style="
                                  height: 1.1rem;
                                  font-size: 0.85rem;
                                  font-weight: normal;
                                  overflow: hidden;
                                  white-space: nowrap;
                                  margin-bottom: 1.9rem;
                                "
                              >
                                <div class="col-auto q-mr-md">
                                  {{ $t("-raw-general-campus") }}:
                                  {{ pl.CampusName }}
                                </div>

                                <div class="col-auto q-mr-md">
                                  {{ $t("-raw-campus-plan-element-count") }}:
                                  {{ pl.ElementCount }}
                                </div>

                                <div class="col-auto">
                                  {{ $t("-raw-campus-plan-start-at") }}:
                                  {{ handleDate(pl.StartAt) }}
                                </div>

                                <div class="col-auto q-ml-md" v-if="pl.EndAt !== null">
                                  {{ $t("-raw-campus-plan-end-at") }}:
                                  {{ handleDate(pl.EndAt) }}
                                </div>
                              </div>

                              <!-- Buttons -->
                              <div
                                class="row"
                                style="height: 3.75rem; overflow: hidden;"
                              >
                                <div class="col-auto q-mr-md">
                                  <q-btn
                                    round
                                    push
                                    :color="pl.UserPassing ? 'w': 'p'"
                                    size="1.25rem"
                                    @click.stop="
                                      $router.push({
                                        path: `/plan-pass/${pl.PlanID}/${pl.ID}/new`,
                                      })
                                    "
                                  >
                                    <q-icon
                                      :name="
                                        pl.UserPassing
                                          ? 'refresh'
                                          : 'play_arrow'
                                      "
                                      color="black"
                                      size="xl"
                                    />

                                    <q-tooltip
                                      anchor="top right"
                                      self="bottom right"
                                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                      transition-show="jump-right"
                                      transition-hide="jump-left"
                                    >
                                      {{ pl.UserPassing ? $t('-raw-campus-plan-pass-start-again',) : $t('-raw-campus-plan-pass') }}
                                    </q-tooltip>
                                  </q-btn>
                                </div>

                                <div class="col-auto q-mr-sm">
                                  <q-btn
                                    round
                                    push
                                    v-if="pl.UserPassing"
                                    color="p"
                                    size="1.25rem"
                                    @click.stop="
                                      $router.push({
                                        path: `/plan-pass/${pl.PlanID}/${pl.ID}/continue`,
                                      })
                                    "
                                  >
                                    <q-icon
                                      name="east"
                                      color="black"
                                      size="xl"
                                    />

                                    <q-tooltip
                                      anchor="top right"
                                      self="bottom right"
                                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                      transition-show="jump-right"
                                      transition-hide="jump-left"
                                    >
                                      {{ $t('-raw-campus-plan-pass-continue') }}
                                    </q-tooltip>
                                  </q-btn>
                                </div>

                                <div class="col" style="position: relative;">
                                  <q-badge
                                    rounded
                                    color="5"
                                    style="position: absolute; bottom: 0; right: 0; user-select: none;"
                                  >
                                    {{ (i + 1) + "/" + validComPlans.length }}
                                  </q-badge>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </q-carousel-slide>
                    </q-carousel>
                  </div>
                </q-slide-transition>
              </div>
            </q-tab-panel>

            <q-tab-panel name="people" class="q-mt-xs">
              <div class="column row items-center q-pa-md" v-if="!loadedpeople">
                <q-spinner-ball color="primary" size="lg" />
              </div>
              <div class="column row justify-center items-center" v-else>
                <div
                  class="row full-width items-center justify-center"
                  v-show="accessUpdate"
                >
                  <div
                    class="justify-center col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    <q-input
                      borderless
                      type="text"
                      v-model="invite.UserName"
                      :placeholder="$t('-raw-share-invite-placeholder')"
                      class="full-width q-px-md glassy-8"
                      input-class="ubuntu"
                      style="border-radius: 1rem"
                    >
                      <template #after>
                        <q-btn-dropdown
                          flat
                          class="bg-0"
                          style="border-radius: 1rem"
                          dropdown-icon="settings"
                          content-style="border-radius: 1rem;"
                        >
                          <template #label>
                            <q-tooltip
                              anchor="top right"
                              self="bottom right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              transition-show="jump-right"
                              transition-hide="jump-left"
                            >
                              {{ $t("-raw-share-admin-permissions") }}
                            </q-tooltip>
                          </template>
                          <q-list>
                            <q-item
                              v-show="
                                p.Permission !== 'read' &&
                                p.Permission != 'read_details'
                              "
                              v-for="p in $store.getters.accessTypes"
                              :key="p.ID"
                              clickable
                              class="items-center"
                              @click="newMemberPermissionChange(p.Permission)"
                            >
                              <div
                                :class="
                                  invite.Permissions.includes(p.Permission)
                                    ? 'text-v'
                                    : 'text-8'
                                "
                              >
                                {{ p.Name }}
                              </div>
                              <q-icon
                                class="q-px-sm"
                                color="v"
                                v-if="invite.Permissions.includes(p.Permission)"
                                name="done"
                              />
                            </q-item>
                          </q-list>
                        </q-btn-dropdown>

                        <q-btn
                          flat
                          class="bg-1"
                          style="border-radius: 1rem"
                          @click="inviteUser"
                          :disable="
                            invite.UserName === '' || invite.UserName === null
                          "
                        >
                          <q-icon name="person_add" color="p" />
                          <q-tooltip
                            anchor="top right"
                            self="bottom right"
                            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                            transition-show="jump-right"
                            transition-hide="jump-left"
                          >
                            {{ $t("-raw-share-send-invite") }}
                          </q-tooltip>
                        </q-btn>
                      </template>
                    </q-input>
                  </div>
                </div>
                <div
                  class="row full-width items-center justify-center q-py-sm"
                  v-show="accessUpdate"
                >
                  <div
                    class="justify-center col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    <q-separator class="q-my-lg bg-7 full-width" />
                  </div>
                </div>

                <div
                  class="row full-width items-center justify-center q-pb-sm"
                  v-if="confirmations.length === 0"
                >
                  <div
                    class="justify-center text-center text-e col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    {{ $t("-raw-share-no-users") }}
                  </div>
                </div>

                <div
                  v-else
                  class="row full-width items-center justify-center q-pb-sm"
                >
                  <div class="justify-center col-10">
                    <q-scroll-area
                      visible
                      class="q-pa-sm col-12"
                      :style="{
                        height: `calc(${window.innerHeight}px - 29rem)`,
                      }"
                      id="classroom-scroll"
                    >
                      <q-table
                        :columns="peopleColumns"
                        :filter="peopleFilter"
                        :row-key="(row) => row.ID"
                        :rows="confirmations"
                        :rows-per-page-label="
                          $t('-raw-classroom-table-people-rows-per-page')
                        "
                        :rows-per-page-options="[10, 20, 50, 100, 0]"
                        class="ubuntu"
                        style="border-radius: 1rem; font-size: 14px !important"
                      >
                        <template #top-right>
                          <q-input
                            v-model="peopleFilter"
                            :label="$t('-raw-main-search')"
                            borderless
                            clearable
                            debounce="300"
                            dense
                            style="width: 200px"
                          >
                            <template #append>
                              <q-icon name="search" />
                            </template>
                          </q-input>
                        </template>
                        <template #body-cell-Admin="props">
                          <td>
                            <q-toggle
                              :model-value="props.value"
                              @update:model-value="
                                toggleUserIsAdmin(props.row.ID)
                              "
                            />
                          </td>
                        </template>
                        <template #body-cell-Actions="props">
                          <td style="text-align: right">
                            <q-btn
                              v-show="
                                accessUpdate && props.row.Status == 'requested'
                              "
                              class="hideCellContent"
                              flat
                              style="border-radius: 1rem"
                              @click="resolveUser(props.row.ID)"
                            >
                              <q-icon color="p" name="task_alt" />
                              <q-tooltip
                                anchor="top right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                self="bottom right"
                                transition-hide="jump-left"
                                transition-show="jump-right"
                              >
                                {{ $t("-raw-share-resolve-request") }}
                              </q-tooltip>
                            </q-btn>
                            <q-btn
                              v-show="
                                accessUpdate &&
                                [
                                  'invited',
                                  'resolved',
                                  'requested',
                                  'confirmed',
                                ].includes(props.row.Status)
                              "
                              class="hideCellContent"
                              flat
                              style="border-radius: 1rem"
                              @click="rejectUser(props.row.ID)"
                            >
                              <q-icon
                                v-if="
                                  ['invited', 'requested'].includes(
                                    props.row.Status,
                                  )
                                "
                                color="n"
                                name="cancel"
                              />
                              <q-icon
                                v-if="
                                  ['resolved', 'confirmed'].includes(
                                    props.row.Status,
                                  )
                                "
                                color="n"
                                name="logout"
                              />
                              <q-tooltip
                                anchor="top right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                self="bottom right"
                                transition-hide="jump-left"
                                transition-show="jump-right"
                              >
                                {{
                                  {
                                    invited: $t("-raw-share-cancel-invitation"),
                                    resolved: $t(
                                      "-raw-share-cancel-membership",
                                    ),
                                    requested: $t("-raw-share-reject-request"),
                                    confirmed: $t(
                                      "-raw-share-cancel-membership",
                                    ),
                                  }[props.row.Status]
                                }}
                              </q-tooltip>
                            </q-btn>
                            <q-btn
                              v-show="
                                accessUpdate &&
                                ['rejected', 'canceled'].includes(
                                  props.row.Status,
                                )
                              "
                              class="hideCellContent"
                              flat
                              style="border-radius: 1rem"
                              @click="inviteUserAgain(props.row.ID)"
                            >
                              <q-icon color="p" name="wifi_protected_setup" />
                              <q-tooltip
                                anchor="top right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                self="bottom right"
                                transition-hide="jump-left"
                                transition-show="jump-right"
                              >
                                {{ $t("-raw-share-send-invite") }}
                              </q-tooltip>
                            </q-btn>
                          </td>
                        </template>
                      </q-table>

                      <!--                      <q-list class="full-width q-gutter-y-sm q-pr-md">-->
                      <!--                        <q-item-->
                      <!--                          v-for="(s, inxs) in confirmations"-->
                      <!--                          :key="'people_' + s.ID"-->
                      <!--                          class="ubuntu row q-px-md glassy-8 text-8 items-center"-->
                      <!--                          style="border-radius: 1rem"-->
                      <!--                          v-show="-->
                      <!--                            accessUpdate ||-->
                      <!--                            s.Status == 'resolved' ||-->
                      <!--                            s.Status == 'confirmed'-->
                      <!--                          "-->
                      <!--                        >-->
                      <!--                          <q-item-section>-->
                      <!--                            {{ s.SharedWithName }}-->
                      <!--                          </q-item-section>-->
                      <!--                          <q-item-section-->
                      <!--                            v-show="accessUpdate"-->
                      <!--                            class="text-italic"-->
                      <!--                            :class="[-->
                      <!--                              s.Status == 'invited' || s.Status == 'requested'-->
                      <!--                                ? 'text-w'-->
                      <!--                                : s.Status == 'rejected' ||-->
                      <!--                                    s.Status == 'canceled'-->
                      <!--                                  ? 'text-n'-->
                      <!--                                  : s.Status == 'resolved' ||-->
                      <!--                                      s.Status == 'confirmed'-->
                      <!--                                    ? 'text-p'-->
                      <!--                                    : '',-->
                      <!--                            ]"-->
                      <!--                          >-->
                      <!--                            <span v-if="s.Status == 'invited'">-->
                      <!--                              {{ $t("-raw-share-status-invited") }}-->
                      <!--                            </span>-->
                      <!--                            <span v-if="s.Status == 'rejected'">-->
                      <!--                              {{ $t("-raw-share-status-rejected") }}-->
                      <!--                            </span>-->
                      <!--                            <span v-if="s.Status == 'resolved'">-->
                      <!--                              {{ $t("-raw-share-status-resolved-2") }}-->
                      <!--                            </span>-->
                      <!--                            <span v-if="s.Status == 'requested'">-->
                      <!--                              {{ $t("-raw-share-status-requested") }}-->
                      <!--                            </span>-->
                      <!--                            <span v-if="s.Status == 'canceled'">-->
                      <!--                              {{ $t("-raw-share-status-canceled") }}-->
                      <!--                            </span>-->
                      <!--                            <span v-if="s.Status == 'confirmed'">-->
                      <!--                              {{ $t("-raw-share-status-confirmed") }}-->
                      <!--                            </span>-->
                      <!--                          </q-item-section>-->
                      <!--                          <q-item-section style="text-align: right" no-wrap>-->
                      <!--                            <div>-->
                      <!--                              &lt;!&ndash; v-show = "&& s.Status !== 'requested' &ndash;&gt;-->
                      <!--                              <q-btn-dropdown-->
                      <!--                                flat-->
                      <!--                                class="bg-0"-->
                      <!--                                style="border-radius: 1rem"-->
                      <!--                                dropdown-icon="settings"-->
                      <!--                                content-style="border-radius: 1rem;"-->
                      <!--                                v-show="accessUpdate"-->
                      <!--                              >-->
                      <!--                                <template #label>-->
                      <!--                                  <q-tooltip-->
                      <!--                                    anchor="top right"-->
                      <!--                                    self="bottom right"-->
                      <!--                                    class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"-->
                      <!--                                    transition-show="jump-right"-->
                      <!--                                    transition-hide="jump-left"-->
                      <!--                                  >-->
                      <!--                                    {{ $t("-raw-share-admin-permissions") }}-->
                      <!--                                  </q-tooltip>-->
                      <!--                                </template>-->
                      <!--                                <q-list>-->
                      <!--                                  <q-item-->
                      <!--                                    v-show="-->
                      <!--                                      p.Permission !== 'read' &&-->
                      <!--                                      p.Permission != 'read_details'-->
                      <!--                                    "-->
                      <!--                                    v-for="p in $store.getters.accessTypes"-->
                      <!--                                    :key="p.ID"-->
                      <!--                                    clickable-->
                      <!--                                    class="items-center"-->
                      <!--                                    @click="-->
                      <!--                                      existMemberPermissionChange(-->
                      <!--                                        p.Permission,-->
                      <!--                                        p.ID,-->
                      <!--                                      )-->
                      <!--                                    "-->
                      <!--                                  >-->
                      <!--                                    <div-->
                      <!--                                      :class="-->
                      <!--                                        s.Permissions.includes(p.Permission)-->
                      <!--                                          ? 'text-v'-->
                      <!--                                          : 'text-8'-->
                      <!--                                      "-->
                      <!--                                    >-->
                      <!--                                      {{ p.Name }}-->
                      <!--                                    </div>-->
                      <!--                                    <q-icon-->
                      <!--                                      class="q-px-sm"-->
                      <!--                                      color="v"-->
                      <!--                                      v-if="-->
                      <!--                                        s.Permissions.includes(p.Permission)-->
                      <!--                                      "-->
                      <!--                                      name="done"-->
                      <!--                                    />-->
                      <!--                                  </q-item>-->
                      <!--                                </q-list>-->
                      <!--                              </q-btn-dropdown>-->

                      <!--                              <q-btn-->
                      <!--                                flat-->
                      <!--                                style="border-radius: 1rem"-->
                      <!--                                @click="resolveUser(s.ID)"-->
                      <!--                                v-show="accessUpdate && s.Status == 'requested'"-->
                      <!--                              >-->
                      <!--                                <q-icon name="task_alt" color="p" />-->
                      <!--                                <q-tooltip-->
                      <!--                                  anchor="top right"-->
                      <!--                                  self="bottom right"-->
                      <!--                                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"-->
                      <!--                                  transition-show="jump-right"-->
                      <!--                                  transition-hide="jump-left"-->
                      <!--                                >-->
                      <!--                                  {{ $t("-raw-share-resolve-request") }}-->
                      <!--                                </q-tooltip>-->
                      <!--                              </q-btn>-->

                      <!--                              <q-btn-->
                      <!--                                flat-->
                      <!--                                style="border-radius: 1rem"-->
                      <!--                                @click="rejectUser(s.ID)"-->
                      <!--                                v-show="-->
                      <!--                                  accessUpdate &&-->
                      <!--                                  (s.Status == 'invited' ||-->
                      <!--                                    s.Status == 'resolved' ||-->
                      <!--                                    s.Status == 'requested' ||-->
                      <!--                                    s.Status == 'confirmed')-->
                      <!--                                "-->
                      <!--                              >-->
                      <!--                                <q-icon-->
                      <!--                                  name="cancel"-->
                      <!--                                  v-if="-->
                      <!--                                    s.Status == 'invited' ||-->
                      <!--                                    s.Status == 'requested'-->
                      <!--                                  "-->
                      <!--                                  color="n"-->
                      <!--                                />-->
                      <!--                                <q-icon-->
                      <!--                                  name="logout"-->
                      <!--                                  v-if="-->
                      <!--                                    s.Status == 'resolved' ||-->
                      <!--                                    s.Status == 'confirmed'-->
                      <!--                                  "-->
                      <!--                                  color="n"-->
                      <!--                                />-->
                      <!--                                <q-tooltip-->
                      <!--                                  anchor="top right"-->
                      <!--                                  self="bottom right"-->
                      <!--                                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"-->
                      <!--                                  transition-show="jump-right"-->
                      <!--                                  transition-hide="jump-left"-->
                      <!--                                >-->
                      <!--                                  <span v-if="s.Status == 'invited'">-->
                      <!--                                    {{ $t("-raw-share-cancel-invitation") }}-->
                      <!--                                  </span>-->
                      <!--                                  <span v-if="s.Status == 'resolved'">-->
                      <!--                                    {{ $t("-raw-share-cancel-membership") }}-->
                      <!--                                  </span>-->
                      <!--                                  <span v-if="s.Status == 'requested'">-->
                      <!--                                    {{ $t("-raw-share-reject-request") }}-->
                      <!--                                  </span>-->
                      <!--                                  <span v-if="s.Status == 'confirmed'">-->
                      <!--                                    {{ $t("-raw-share-cancel-membership") }}-->
                      <!--                                  </span>-->
                      <!--                                </q-tooltip>-->
                      <!--                              </q-btn>-->

                      <!--                              <q-btn-->
                      <!--                                flat-->
                      <!--                                style="border-radius: 1rem"-->
                      <!--                                @click="inviteUserAgain(s.ID)"-->
                      <!--                                v-show="-->
                      <!--                                  accessUpdate &&-->
                      <!--                                  (s.Status == 'rejected' ||-->
                      <!--                                    s.Status == 'canceled')-->
                      <!--                                "-->
                      <!--                              >-->
                      <!--                                <q-icon name="wifi_protected_setup" color="p" />-->
                      <!--                                <q-tooltip-->
                      <!--                                  anchor="top right"-->
                      <!--                                  self="bottom right"-->
                      <!--                                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"-->
                      <!--                                  transition-show="jump-right"-->
                      <!--                                  transition-hide="jump-left"-->
                      <!--                                >-->
                      <!--                                  {{ $t("-raw-share-send-invite") }}-->
                      <!--                                </q-tooltip>-->
                      <!--                              </q-btn>-->
                      <!--                            </div>-->
                      <!--                          </q-item-section>-->
                      <!--                        </q-item>-->
                      <!--                      </q-list>-->
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </q-tab-panel>
            <q-tab-panel name="campuses" class="q-mt-xs">
              <div
                class="column row items-center q-pa-md"
                v-if="!loadedcampuses"
              >
                <q-spinner-ball color="primary" size="lg" />
              </div>
              <div class="column row items-center q-pa-md" v-else>
                <!-- campuses -->
                <div
                  class="row full-width items-center justify-center"
                  v-show="accessUpdate"
                >
                  <div
                    class="justify-center col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    <q-select
                      use-input
                      clearable
                      borderless
                      type="text"
                      v-model="inviteCampusObject"
                      :options="inviteCampusOptions"
                      option-label="Name"
                      @filter="inviteCampusAutocomplete"
                      :placeholder="
                        inviteCampusObject?.Name
                          ? ''
                          : $t('-raw-classroom-invite-campus')
                      "
                      class="full-width q-px-md glassy-8"
                      input-class="ubuntu"
                      style="border-radius: 1rem"
                    >
                      <template #no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            {{ $t("-raw-404-nothing-found") }}
                          </q-item-section>
                        </q-item>
                      </template>
                      <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt?.Name }}</q-item-label>
                            <q-item-label
                              v-if="(scope.opt?.Languages || []).length > 0"
                              caption
                              >{{
                                $t("-raw-campus-language") +
                                ": " +
                                scope.opt.Languages
                              }}</q-item-label
                            >
                          </q-item-section>
                        </q-item>
                      </template>
                      <template #after>
                        <q-btn
                          flat
                          class="bg-1"
                          style="border-radius: 1rem"
                          @click="inviteCampus"
                          :disable="(inviteCampusObject?.Name ?? '') === ''"
                        >
                          <q-icon name="group_add" color="p" />
                          <q-tooltip
                            anchor="top right"
                            self="bottom right"
                            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                            transition-show="jump-right"
                            transition-hide="jump-left"
                          >
                            {{ $t("-raw-share-send-invite") }}
                          </q-tooltip>
                        </q-btn>
                        <q-btn
                          flat
                          style="border-radius: 1rem"
                          @click="searchcampusdialog = true"
                        >
                          <q-icon name="search" color="e" />
                          <q-tooltip
                            anchor="top right"
                            self="bottom right"
                            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                            transition-show="jump-right"
                            transition-hide="jump-left"
                          >
                            {{ $t("-raw-campus-search") }}
                          </q-tooltip>
                        </q-btn>
                      </template>
                    </q-select>
                  </div>
                </div>
                <div
                  class="row full-width items-center justify-center q-py-sm"
                  v-show="accessUpdate"
                >
                  <div
                    class="justify-center col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    <q-separator class="q-my-lg bg-7 full-width" />
                  </div>
                </div>

                <div
                  class="row full-width items-center justify-center q-pb-sm"
                  v-if="campuses.length === 0"
                >
                  <div
                    class="justify-center text-center text-e col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    {{ $t("-raw-classroom-no-campuses") }}
                  </div>
                </div>

                <div class="row full-width items-center justify-center">
                  <div
                    class="justify-center col-xl-6 col-lg-6 col-md-7 col-sm-9 col-xs-10"
                  >
                    <q-scroll-area
                      visible
                      class="q-pa-sm col-12"
                      :style="{
                        height: `calc(${window.innerHeight}px - 29rem)`,
                      }"
                      id="classroom-scroll"
                    >
                      <q-list class="full-width q-gutter-y-sm q-pr-md">
                        <q-item
                          v-for="(s, inxs) in campuses"
                          :key="'campus_' + s.ID"
                          class="ubuntu row q-px-md glassy-8 text-8 items-center"
                          style="border-radius: 1rem"
                          v-show="
                            accessUpdate ||
                            s.Status == 'resolved' ||
                            s.Status == 'confirmed'
                          "
                        >
                          <q-item-section>
                            <div
                              :style="
                                s.CampusDeletedAt
                                  ? 'text-decoration: line-through;'
                                  : ''
                              "
                            >
                              {{ s.CampusName }}
                              <q-tooltip
                                v-if="s.CampusDeletedAt"
                                anchor="top right"
                                self="bottom right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                transition-show="jump-up"
                                transition-hide="jump-down"
                              >
                                {{ $t("-raw-campus-deleted") }}
                              </q-tooltip>
                            </div>
                          </q-item-section>
                          <q-item-section
                            v-show="accessUpdate"
                            class="text-italic"
                            :class="
                              {
                                invited: 'text-w',
                                requested: 'text-w',
                                rejected: 'text-n',
                                canceled: 'text-n',
                                resolved: 'text-p',
                                confirmed: 'text-p',
                              }[s.Status]
                            "
                          >
                            <span>
                              {{
                                {
                                  invited: $t("-raw-share-status-invited"),
                                  rejected: $t("-raw-share-status-rejected"),
                                  resolved: $t("-raw-share-status-resolved-2"),
                                  requested: $t("-raw-share-status-requested"),
                                  canceled: $t("-raw-share-status-canceled"),
                                  confirmed: $t("-raw-share-status-confirmed"),
                                }[s.Status]
                              }}
                            </span>
                          </q-item-section>
                          <q-item-section style="text-align: right" no-wrap>
                            <div>
                              <!-- v-show = "&& s.Status !== 'requested' -->
                              <q-btn
                                flat
                                style="border-radius: 1rem"
                                @click="resolveCampus(inxs)"
                                v-show="accessUpdate && s.Status == 'requested'"
                              >
                                <q-icon name="task_alt" color="p" />
                                <q-tooltip
                                  anchor="top right"
                                  self="bottom right"
                                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                  transition-show="jump-right"
                                  transition-hide="jump-left"
                                >
                                  {{ $t("-raw-share-resolve-request") }}
                                </q-tooltip>
                              </q-btn>

                              <q-btn
                                flat
                                style="border-radius: 1rem"
                                @click="rejectCampus(inxs)"
                                v-show="
                                  accessUpdate &&
                                  (s.Status == 'invited' ||
                                    s.Status == 'resolved' ||
                                    s.Status == 'requested' ||
                                    s.Status == 'confirmed')
                                "
                              >
                                <q-icon
                                  name="cancel"
                                  v-if="
                                    s.Status == 'invited' ||
                                    s.Status == 'requested'
                                  "
                                  color="n"
                                />
                                <q-icon
                                  name="logout"
                                  v-if="
                                    s.Status == 'resolved' ||
                                    s.Status == 'confirmed'
                                  "
                                  color="n"
                                />
                                <q-tooltip
                                  anchor="top right"
                                  self="bottom right"
                                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                  transition-show="jump-right"
                                  transition-hide="jump-left"
                                >
                                  <span v-if="s.Status == 'invited'">
                                    {{ $t("-raw-share-cancel-invitation") }}
                                  </span>
                                  <span v-if="s.Status == 'resolved'">
                                    {{ $t("-raw-share-cancel-membership") }}
                                  </span>
                                  <span v-if="s.Status == 'requested'">
                                    {{ $t("-raw-share-reject-request") }}
                                  </span>
                                  <span v-if="s.Status == 'confirmed'">
                                    {{ $t("-raw-share-cancel-membership") }}
                                  </span>
                                </q-tooltip>
                              </q-btn>

                              <q-btn
                                flat
                                style="border-radius: 1rem"
                                @click="inviteCampusAgain(inxs)"
                                v-show="
                                  accessUpdate &&
                                  (s.Status == 'rejected' ||
                                    s.Status == 'canceled')
                                "
                              >
                                <q-icon name="wifi_protected_setup" color="p" />
                                <q-tooltip
                                  anchor="top right"
                                  self="bottom right"
                                  class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                  transition-show="jump-right"
                                  transition-hide="jump-left"
                                >
                                  {{ $t("-raw-share-send-invite") }}
                                </q-tooltip>
                              </q-btn>
                            </div>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </q-tab-panel>

            <q-tab-panel name="reports" class="q-mt-xs">
              <!-- <div
                class="column row items-center q-pa-md text-e text-bold text-italic"
                v-if="true"
              >
                {{ $t("-raw-classroom-coming-soon") }}
              </div> -->

              <div class="column row items-center q-pa-md text-e text-bold">
                <Reports
                  :data="{ objectid: pclassroomuid, objecttype: 'classroom' }"
                  :view="{ fit: false }"
                  class="fit"
                />
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </q-card>
    </div>
    <q-dialog
      v-model="searchcampusdialog"
      :maximized="searchcampusdialogMax"
      persistent
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-card-section
          :style="{
            height: `calc(${window.innerHeight}px - 5rem) !important`,
          }"
        >
          <q-btn
            text-color="0"
            class="bg-e"
            v-close-popup
            icon="close"
            style="
              position: absolute;
              right: 1rem;
              width: 2rem;
              height: 2rem;
              z-index: 9999 !important;
            "
            no-caps
          ></q-btn>
          <Campuses
            :data="{ languages: classroomlanguages }"
            @choose="campusChooseFromList"
            class="fit"
          />
        </q-card-section>
        <q-card-actions align="right" class="q-pr-md">
          <q-btn
            color="e"
            outline
            dense
            rounded
            class="q-py-md bg-2"
            icon-left="arrow_back"
            no-caps
            v-close-popup
            style="height: 3rem"
          >
            <span class="q-mx-md">{{ $t("-raw-general-cancel") }}</span>
          </q-btn>
          <q-btn
            :color="campusInviteChosen == null ? 'v' : 'p'"
            dense
            rounded
            icon-right="arrow_forward"
            no-caps
            v-close-popup
            style="height: 3rem"
            :disabled="campusInviteChosen == null"
            text-color="0"
            @click="campusInviteConfirm"
          >
            <span class="q-mx-md">{{ $t("-raw-share-send-invite") }}</span>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import api from "src/h/api.js";
import h from "src/h/helpers.js";
import Campuses from "src/pages/campuses/index.vue";
import pickerimage from "src/components/picker-picture/picker.vue";
import Reports from "src/pages/reports/index.vue";
import { i18n } from "src/boot/i18n.js";
export default {
  emits: ["save", "choosetab"],
  props: {
    pclassroomuid: {
      type: String,
      default: "",
    },
    ptab: {
      type: String,
      default: "",
    },
    pclassroomlanguages: {
      type: Array,
      default: new Array(),
    },
  },
  components: { Campuses, pickerimage, Reports },
  data: () => ({
    paramsURL: {},
    tabclassroom: null,
    loadedplanscurrent: false,
    loadedplanscompleted: false,
    loadedpeople: false,
    loadedcampuses: false,
    plantoggleshow: null,
    invite: {
      UserName: null,
      Permissions: [],
    },
    currentPlans: [],
    completedPlans: [],
    inviteCampusObject: null,
    inviteCampusOptions: [],
    campuses: [],
    confirmations: [], // People, { ID: 0, SharedWithName: "abcname", Status: "rejected", Permissions: ["read","read_details"] }],
    curPlanSlide: 0,
    comPlanSlide: 0,
    topcontent: 100,
    searchcampusdialog: false,
    searchcampusdialogMax: true,
    campusInviteChosen: null,
    classroomlanguages: [],
    peopleColumns: [
      {
        name: "Name",
        required: true,
        label: i18n.global.t("-raw-classroom-table-people-username"),
        align: "left",
        field: (row) => row.SharedWithName,
        format: (val) => val,
        sortable: true,
        sort: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        classes: "overflow-hidden",
        headerStyle: "width: 50%",
      },
      {
        name: "Status",
        required: true,
        label: i18n.global.t("-raw-classroom-table-people-userstatus"),
        align: "left",
        field: (row) => {
          return {
            invited: i18n.global.t("-raw-share-status-invited"),
            rejected: i18n.global.t("-raw-share-status-rejected"),
            resolved: i18n.global.t("-raw-share-status-resolved-2"),
            requested: i18n.global.t("-raw-share-status-requested"),
            canceled: i18n.global.t("-raw-share-status-canceled"),
            confirmed: i18n.global.t("-raw-share-status-confirmed"),
          }[row.Status];
        },
        format: (val) => val,
        sortable: true,
        classes: (row) =>
          "text-italic overflow-hidden " +
          {
            invited: "text-w",
            requested: "text-w",
            rejected: "text-n",
            canceled: "text-n",
            resolved: "text-p",
            confirmed: "text-p",
          }[row.Status],
        sort: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        headerStyle: "width: 20%",
      },
      {
        name: "Admin",
        required: true,
        label: i18n.global.t("-raw-classroom-table-people-user-is-admin"),
        align: "left",
        field: (row) => row.Permissions.includes("update"),
        format: (val) => val,
        sortable: true,
        headerStyle: "width: 10%",
      },
      {
        name: "Actions",
        required: true,
        label: i18n.global.t("-raw-classroom-table-people-actions"),
        align: "middle",
        headerStyle: "width: 20%",
      },
    ],
    peopleFilter: null,
  }),
  computed: {
    accessUpdate() {
      return this.$store.getters.currentClassroomAccess?.update;
    },
    validCurPlans() {
      return this.currentPlans.filter((plan) => plan.ElementCount > 0);
    },
    validComPlans() {
      return this.completedPlans.filter((plan) => plan.ElementCount > 0);
    },
    isSimpleUI() {
      return h.getItem("VOC_USER_SIMPLE_UI") === true;
    },
  },
  watch: {
    accessUpdate: {
      handler(newVal, oldVal) {
        // if user open 'people', but access was changed (need open 'plans')
        if (oldVal !== undefined && newVal !== oldVal) {
          if (newVal == false && this.tabclassroom !== "plans") {
            this.tabclassroom = "plans";
          }
        }
      },
      immediate: true,
    },
    tabclassroom: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.changeActiveTab();
        }
      },
      immediate: true,
    },
    plantoggleshow: {
      handler(newVal, oldVal) {
        if (oldVal !== undefined && newVal && newVal !== oldVal) {
          if (newVal === "current") {
            this.getPlansCurrentTab();
          } else if (newVal === "completed") {
            this.getPlansCompletedTab();
          }
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    if (
      this.ptab !== "plans" &&
      this.ptab !== "people" &&
      this.ptab !== "campuses"
    ) {
      this.tabclassroom = "plans";
    } else {
      this.tabclassroom = this.ptab;
    }
    if (this.pclassroomlanguages) {
      this.classroomlanguages.splice(
        0,
        this.classroomlanguages.length,
        ...this.pclassroomlanguages,
      );
    }
  },
  // beforeUnmount() {},
  mounted() {
    this.topcontent = document
      .getElementById("classroom-content")
      .getBoundingClientRect().top;
  },
  methods: {
    parseParamsUrl() {
      if (!this.$router.currentRoute.value.query) {
        return;
      }

      this.paramsURL = {};
      var qr = this.$router.currentRoute.value.query;
      for (var name in qr) {
        this.paramsURL[name] = qr[name];
      }
    },
    setParamsUrl(params) {
      const query = Object.assign({}, this.$route.query);

      for (var name in params) {
        this.paramsURL[name] = encodeURIComponent(params[name]);
      }
      for (var name in this.paramsURL) {
        if (this.paramsURL[name]?.length > 0) {
          query[name] = this.paramsURL[name];
        } else {
          query[name] = "";
        }
      }
      this.$router.push({ query });
    },
    syncTab(val) {
      this.tabclassroom = val;
      this.$store.commit("setCurrentClassroomTab", this.tabclassroom);
    },
    changeActiveTab() {
      switch (this.tabclassroom) {
        case "plans":
          this.getPlansTab();
          break;
        case "people":
          this.getPeopleTab();
          break;
        case "campuses":
          this.getCampusesTab();
          break;
      }
      this.$store.commit("setCurrentClassroomTab", this.tabclassroom);
      this.$emit("choosetab", this.tabclassroom);
    },
    getPlansTab() {
      if (this.plantoggleshow == null) {
        this.parseParamsUrl(); // in beforeMount don't work
        let tp = this.paramsURL["tp"];
        if (tp === "completed") {
          this.plantoggleshow = "completed";
        } else {
          this.plantoggleshow = "current";
        }
      }
    },
    getPlansCurrentTab() {
      this.setParamsUrl({ tp: "current" });
      if (this.loadedplanscurrent) {
        return;
      }

      this.loadedplanscurrent = false;
      api
        .Call({
          url: "/api/v1/classroom_plan/current/" + this.pclassroomuid,
          show_error: true,
        })
        .then((result) => {
          this.currentPlans = result ?? [];
        })
        .finally(() => {
          this.loadedplanscurrent = true;
        });
    },
    getPlansCompletedTab() {
      this.setParamsUrl({ tp: "completed" });
      if (this.loadedplanscompleted) {
        return;
      }

      this.loadedplanscompleted = false;
      api
        .Call({
          url: "/api/v1/classroom_plan/completed/" + this.pclassroomuid,
          show_error: true,
        })
        .then((result) => {
          this.completedPlans = result ?? [];
        })
        .finally(() => {
          this.loadedplanscompleted = true;
        });
    },
    getPeopleTab() {
      if (this.loadedpeople) {
        return;
      }

      this.$store.commit("setAccessTypes", []);
      this.$store.dispatch("getAccessTypes", "classroom");

      this.loadedpeople = false;
      api
        .Call({
          url: "/api/v1/share/confirmation/classroom/" + this.pclassroomuid,
          show_error: true,
        })
        .then(
          (result) => {
            this.confirmations = result;
            this.loadedpeople = true;
          },
          (e) => {
            this.loadedpeople = true;
          },
        );
    },
    getCampusesTab() {
      if (this.loadedcampuses) {
        return;
      }
      this.$store.commit("setAccessTypes", []);
      this.$store.dispatch("getAccessTypes", "classroom");

      this.loadedcampuses = false;
      api
        .Call({
          url: "/api/v1/classroom_campus/classroom/" + this.pclassroomuid,
          show_error: true,
        })
        .then(
          (result) => {
            this.campuses = result;
            this.loadedcampuses = true;
          },
          (e) => {
            this.loadedcampuses = true;
          },
        );
    },
    newMemberPermissionChange(val) {
      let index = this.invite.Permissions.indexOf(val);
      if (index > -1) {
        this.invite.Permissions.splice(index, 1);
      } else {
        this.invite.Permissions.push(val);
      }
    },
    toggleUserIsAdmin(confirmationID) {
      this.existMemberPermissionChange("update", confirmationID);
      this.existMemberPermissionChange("delete", confirmationID);
    },
    existMemberPermissionChange(val, confirmationID) {
      const confirmation = this.confirmations.find(
        (conf) => conf.ID === confirmationID,
      );
      if (!confirmation) {
        console.error(
          "existMemberPermissionChange: Confirmation not found",
          confirmationID,
        );
        return;
      }
      let index = confirmation.Permissions.indexOf(val);
      if (index > -1) {
        confirmation.Permissions.splice(index, 1);
      } else {
        confirmation.Permissions.push(val);
      }
      this.save(confirmation, "update_permission_user");
    },
    save(data, method) {
      this.$emit("save", data, method);
    },
    campusInviteConfirm() {
      if (!this.campusInviteChosen) return;
      this.save(
        {
          CampusID: this.campusInviteChosen,
          ClassroomID: this.pclassroomuid,
        },
        "invite_campus",
      );
    },
    campusChooseFromList(ID) {
      this.campusInviteChosen = ID;
    },
    inviteCampus() {
      if (!this.inviteCampusObject?.Name) return;
      this.save(
        {
          CampusName: this.inviteCampusObject.Name,
          ClassroomID: this.pclassroomuid,
        },
        "invite_campus",
      );
    },
    inviteCampusAgain(pindx) {
      this.save(
        {
          CampusName: this.campuses[pindx].CampusName,
          ClassroomID: this.pclassroomuid,
        },
        "invite_campus",
      );
    },
    rejectCampus(pindx) {
      this.save(
        {
          CampusName: this.campuses[pindx].CampusName,
          ClassroomID: this.pclassroomuid,
        },
        "reject_campus",
      );
    },
    resolveCampus(pindx) {
      this.save(
        {
          CampusName: this.campuses[pindx].CampusName,
          ClassroomID: this.pclassroomuid,
        },
        "resolve_campus",
      );
    },
    inviteUser() {
      this.save(
        {
          UserName: this.invite.UserName,
          Permissions: this.invite.Permissions,
          ObjectID: this.pclassroomuid,
        },
        "invite_user",
      );
    },
    inviteUserAgain(confirmationID) {
      const user = this.confirmations.find(
        (conf) => conf.ID === confirmationID,
      );
      if (!user) {
        console.error("inviteUserAgain: User not found", confirmationID);
        return;
      }
      this.save(
        {
          UserName: user.SharedWithName,
          Permissions: user.Permissions,
          ObjectID: this.pclassroomuid,
        },
        "invite_user",
      );
    },
    rejectUser(confirmationID) {
      const user = this.confirmations.find(
        (conf) => conf.ID === confirmationID,
      );
      if (!user) {
        console.error("rejectUser: User not found", confirmationID);
        return;
      }
      this.save(
        {
          UserName: user.SharedWithName,
          Permissions: user.Permissions,
          ObjectID: this.pclassroomuid,
        },
        "reject_user",
      );
    },
    resolveUser(confirmationID) {
      const user = this.confirmations.find(
        (conf) => conf.ID === confirmationID,
      );
      if (!user) {
        console.error("resolveUser: User not found", confirmationID);
        return;
      }
      this.save(
        {
          UserName: user.SharedWithName,
          Permissions: user.Permissions,
          ObjectID: this.pclassroomuid,
        },
        "resolve_user",
      );
    },
    sync(data, objname) {
      switch (objname) {
        case "people_member":
          let ext = -1;
          for (let i = 0; i < this.confirmations.length; i++) {
            if (this.confirmations[i].SharedWith == data.SharedWith) {
              ext = i;
              break;
            }
          }
          if (ext >= 0) {
            this.confirmations[ext] = data;
          } else {
            this.confirmations.splice(0, 0, data);
          }

          if (
            (this.invite.UserName === data.SharedWithName ||
              this.invite.UserName === data.SharedWithEmail) &&
            data.Status == "invited"
          ) {
            this.invite.UserName = "";
          }
          break;

        case "classroom_campus":
          let extCmp = -1;
          for (let i = 0; i < this.campuses.length; i++) {
            if (this.campuses[i].CampusID == data.CampusID) {
              extCmp = i;
              break;
            }
          }
          if (extCmp >= 0) {
            this.campuses[extCmp] = data;
          } else {
            this.campuses.splice(0, 0, data);
          }

          if (
            this.inviteCampusObject?.Name === data.CampusName &&
            data.Status === "invited"
          ) {
            this.inviteCampusObject = null;
          }
          break;

        case "classroom_plan_created":
        case "classroom_plan_updated":
          {
            let extp = false;
            for (let i = 0; i < this.currentPlans.length; i++) {
              if (this.currentPlans[i].ID === data.ID) {
                // need keep current value (if author update plan, we can't select for each user current status)
                data.UserPassing = this.currentPlans[i].UserPassing;
                h.CopyObject(data, this.currentPlans[i]);
                extp = true;
                break;
              }
            }
            // if (!extp) this.currentPlans.push(data); // date asc
            if (!extp) this.currentPlans.splice(0, 0, data); // date desc
          }
          break;

        case "classroom_plan_deleted":
          for (let i = 0; i < this.currentPlans.length; i++) {
            if (this.currentPlans[i].ID === data) {
              this.currentPlans.splice(i, 1);
              break;
            }
          }
          break;

        case "classroom_plan_finished":
          for (let i = 0; i < this.currentPlans.length; i++) {
            if (this.currentPlans[i].ID === data.ID) {
              this.currentPlans.splice(i, 1);
              this.completedPlans.push(data);
              break;
            }
          }
          break;

        case "campus_plan_element_created":
        case "campus_plan_element_updated":
        case "campus_plan_element_deleted":
        case "campus_plan_updated":
          {
            let extp = false;
            for (let i = 0; i < this.currentPlans.length; i++) {
              if (this.currentPlans[i].ID === data.ID) {
                h.CopyObject(data, this.currentPlans[i]);
                extp = true;
                break;
              }
            }
            if (!extp) this.currentPlans.push(data);
          }
          break;
        case "save_languages":
          this.classroomlanguages.splice(
            0,
            this.classroomlanguages.length,
            ...(data ? data : []),
          );
          break;
      }
    },
    inviteCampusAutocomplete(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(async () => {
        this.inviteCampusOptions = await this.searchCampus(val.toLowerCase());
      });
    },
    searchCampus(nameLike) {
      return api
        .Call({
          url:
            "/api/v1/search/campus?limit=25&allLanguages=true&q=" +
            encodeURI(nameLike),
          method: "get",
        })
        .then(
          (response) => {
            if (!(response === null || response === undefined)) {
              return (
                response.map((item) => ({
                  ...item,
                  Languages: this.makeLanguageList(item.LanguageIDs ?? []),
                })) ?? []
              );
            } else {
              return [];
            }
          },
          (e) => {
            console.error(e);
          },
        );
    },
    makeLanguageList(LanguageIDs) {
      if (!Array.isArray(LanguageIDs)) return null;
      return LanguageIDs.map(
        (langID) =>
          this.$store.getters.languages.find((lang) => lang.ID === langID)
            ?.name,
      );
    },
    handleDate(datesrc) {
      if (!datesrc) return "";
      const date = new Date(datesrc);
      return date.toLocaleDateString();
    },
  },
};
</script>

<style>
/*Table column width fix*/
/* stackoverflow.com/questions/4457506 */
.q-table {
  table-layout: fixed;
}

/*A bit larger font size for the table*/
.q-table tbody td {
  font-size: 14px;
}

/* Initially hide the content of the cell, i.e. for action buttons */
.hideCellContent {
  opacity: 0;
}

/* Show the content when the parent row is being hovered */
tbody > tr:hover .hideCellContent {
  opacity: 1;
}
</style>
